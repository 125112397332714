









































































































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import {VTextField} from 'vuetify/lib/components';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';

export default defineComponent({
  name: 'uOttawa1321Lab1Question5',
  components: {TableInput, STextarea, CalculationInput, VTextField, DragToRankInput},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        aiQuestionResponse: null,
        dragToRankAnswers1: [],
        dragToRankAnswers2: [],
        dragToRankAnswers3: [],
        language: 'en',
      },
      partAImage: [] as File[],
      dragToRankOptions1: ['benzyl alcohol', 'benzaldehyde', 'benzyl acetate'],
      dragToRankOptions2: ['aniline', 'N,N-dimethylaniline', 'naphthalene'],
      dragToRankOptions3: ['benzophenone', 'biphenyl', 'benzoic acid'],
      questions: [
        {
          en: 'For each of the following sets of compounds:',
          fr: 'Pour chacun des groupes de composés suivants :',
        },
        {
          en: '1. Benzyl alcohol, benzaldehyde, and benzyl acetate',
          fr: '1. Alcool benzylique, benzaldéhyde, et acétate de benzyle',
        },
        {
          en: '2. Aniline, N,N-dimethylaniline, and naphthalene',
          fr: '2. Aniline, N,N-diméthylaniline, et naphtalène.',
        },
        {
          en: '3. Benzophenone, biphenyl, and benzoic acid',
          fr: '3. Benzophénone, biphényle, et acide benzoïque',
        },
        {
          en: 'a) Draw the linear structure of each molecule and upload it using the field below.',
          fr: 'a) Dessinez la molécule et utiliser le champ de téléchargement ci-dessous pour le télécharger.',
        },
        {
          en: 'b) Arrange them in order from most polar down to least polar',
          fr: 'b) Classez-les par ordre de polarité, de la plus polaire à la moins polaire.',
        },
        {
          en: 'c) Explain your reasoning for the ordering you chose in part b).',
          fr: "c) Expliquez votre raisonnement pour l'ordre dans la partie b).",
        },
      ],
    };
  },
  computed: {
    attachments(): File[] {
      return [...this.partAImage];
    },
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
